import { Checkbox, Tooltip } from '@mui/material';
import React from 'react';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';

interface CustomCheckBoxCellProperties {
    dataItem?: any,
    field: string,
    selectedAll?: boolean,
    tooltipMessage: string,
    _updateElements: (checked: boolean, dataItem?: PlanningVehicleLightModelExtended) => void
}

export const CustomCheckBoxCell = (props: CustomCheckBoxCellProperties) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (props.field == "selectedAll") {
            props._updateElements(event.target.checked);
        }
        else {
            props._updateElements(event.target.checked, props.dataItem);
        }
    };

    const defaultRendering: JSX.Element = (
        <>
            {props.field == "selectedAll" ?
                (<Tooltip title={props.tooltipMessage} placement="bottom">
                    <Checkbox
                        checked={props.selectedAll}
                        onChange={(e) => handleChange(e)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Tooltip>
                ) :
                <td>
                    <Tooltip title={props.tooltipMessage} placement="bottom">
                        <Checkbox
                            checked={props.dataItem[props.field]}
                            onChange={(e) => handleChange(e)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Tooltip>
                </td>
            }
        </>
    );

    return defaultRendering;
}